/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export default function Line({ vertical }) {
	return vertical ? (
		<Box
			sx={{
				height: 'xxxl',
				width: '0',
				margin: '0 auto',
				borderLeft: (theme) => `2px solid ${theme.colors.orange_50}`,
			}}
		/>
	) : (
		<hr
			sx={{
				height: '2px',
				margin: '0',
				border: 'none',
				bg: 'orange_50',
				// borderTop: (theme) => `2px solid ${theme.colors.orange_50}`,
			}}
		/>
	);
}
