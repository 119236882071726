/** @jsx jsx */
import { jsx, Box } from 'theme-ui';

export default function Email() {
	return (
		<Box
			dangerouslySetInnerHTML={{
				__html: `${
					'<' +
					'a' +
					' ' +
					'h' +
					'r' +
					'e' +
					'f' +
					'=' +
					"'" +
					'm' +
					'a' +
					'&' +
					'#' +
					'1' +
					'0' +
					'5' +
					';' +
					'l' +
					't' +
					'o' +
					'&' +
					'#' +
					'5' +
					'8' +
					';' +
					'%' +
					'6' +
					'8' +
					'e' +
					'l' +
					'l' +
					'%' +
					'6' +
					'F' +
					'&' +
					'#' +
					'6' +
					'4' +
					';' +
					'a' +
					'k' +
					'&' +
					'#' +
					'1' +
					'1' +
					'5' +
					';' +
					'&' +
					'#' +
					'1' +
					'0' +
					'4' +
					';' +
					'%' +
					'6' +
					'1' +
					'y' +
					'%' +
					'7' +
					'6' +
					'e' +
					'%' +
					'7' +
					'2' +
					'm' +
					'a' +
					'&' +
					'#' +
					'4' +
					'6' +
					';' +
					'n' +
					'e' +
					't' +
					'?subject=Hi Akshay! (source: website)' +
					"'" +
					'>' +
					'Email (' +
					'h' +
					'e' +
					'&' +
					'#' +
					'1' +
					'0' +
					'8' +
					';' +
					'l' +
					'o' +
					'&' +
					'#' +
					'6' +
					'4' +
					';' +
					'&' +
					'#' +
					'9' +
					'7' +
					';' +
					'&' +
					'#' +
					'1' +
					'0' +
					'7' +
					';' +
					'&' +
					'#' +
					'1' +
					'1' +
					'5' +
					';' +
					'h' +
					'a' +
					'y' +
					'v' +
					'e' +
					'r' +
					'm' +
					'&' +
					'#' +
					'9' +
					'7' +
					';' +
					'&' +
					'#' +
					'4' +
					'6' +
					';' +
					'&' +
					'#' +
					'1' +
					'1' +
					'0' +
					';' +
					'e' +
					'&' +
					'#' +
					'1' +
					'1' +
					'6' +
					';' +
					')' +
					'<' +
					'/' +
					'a' +
					'>'
				}`,
			}}
		/>
	);
}
