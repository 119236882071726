/** @jsx jsx */
import { jsx, Themed, Box, Flex } from 'theme-ui';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Line from '../components/line';
import Email from '../components/email';
import logo from '../images/logo.svg';

export default function IndexPage() {
	return (
		<Layout>
			<SEO />
			<Flex
				sx={{
					position: 'relative',
					height: 'calc(100vh - 4rem)',
					minHeight: 'fit-content',
					maxHeight: '45rem',
					flexDirection: 'column',
					justifyContent: 'flex-end',
					alignItems: 'flex-start',
					gap: 'xl',
					pt: 'xl',
					pb: 'xl',
				}}
			>
				<img
					src={logo}
					alt="Logo"
					sx={{
						height: '8rem',
					}}
				/>
				<Themed.h1
					sx={
						{
							// mb: 'xl',
						}
					}
				>
					Hi, I’m Akshay.
				</Themed.h1>
				<Themed.h3 sx={{ color: 'secondary' }}>
					Partner at{' '}
					<a
						href="https://prophecy.one"
						target="_blank"
						sx={{
							variant: 'text.link',
						}}
					>
						Prophecy
					</a>
					, specialising in innovation, digital product design, and leading
					teams.
				</Themed.h3>
			</Flex>
			<Line vertical />
			<Box
				sx={{
					p: { variant: 'text.bodyLarge' },
					mt: 'xl',
				}}
			>
				<p>
					I have been a designer for over 11 years. As an early member of the
					team at Obvious, I helped lead projects, set up processes, and build
					and train the design team. Additionally, I have worked with companies
					like Kotak, Gradeup, Barclays and IKEA on digital products spanning
					education, healthcare, fintech and SaaS.
				</p>
				<p>
					A global public health initiative that I helped design,{' '}
					<a href="https://simple.org" target="_blank">
						Simple
					</a>
					, is now deployed in public health facilities across India and is also
					rapidly rolling out in Bangladesh and Ethiopia.
				</p>
				<p>
					My work has won multiple awards like Fast Company’s Innovation by
					Design, Core77 Design Awards, and a “Best in Show” from the
					Interaction Design Association (IxDA).
				</p>
				<p>
					I also mentor startup founders for the global venture capital firm,
					Antler, and teach product design at Ownpath.
				</p>
				<p>
					I am an alumnus of the National Institute of Design, India and the
					Copenhagen Institute of Interaction Design.
				</p>
			</Box>
			<Flex
				sx={{
					flexDirection: ['column', 'row'],
					rowGap: 'l',
					columnGap: 'xl',
					flexWrap: 'wrap',
					variant: 'text.h6',
					mt: 'xxl',
					mb: 'xxxl',
					a: { variant: 'text.link', width: 'fit-content' },
				}}
			>
				<a href="https://twitter.com/_akshayverma_">Twitter</a>
				<a href="https://www.linkedin.com/in/akshayverma1">LinkedIn</a>
				<Email />
			</Flex>
		</Layout>
	);
}
